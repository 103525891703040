import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import FooterLogo from "../../../../../assets/images/footer-logo.png"
import MailIcon from "../../../../../assets/images/mail-icon.png"
import PhoneIcon from "../../../../../assets/images/phoneIcon.png"
import "./Footer.css"
import { useHref, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { footerSubscribemail } from "../../../../../utils/yupSchema/Schema"
import { FormInput } from "../../../../form"
import { useUserMailSubscriptionMutation } from "../../../../../features/Auth/authApi"
import { toast } from "react-toastify"

import { FaInstagram, FaLinkedin, FaPinterest, FaYoutube } from "react-icons/fa";
import { FaSquareFacebook, FaXTwitter } from "react-icons/fa6";
import { useDebounce } from "../../../../../utils/debouncer"
import useNavigateAndScroll from "../../../../../utils/commonFunctions/navigateAndScroll"
import { useAppSelector } from "../../../../../app/hooks"
import { isLaunched } from "../../../../../constants"

interface EmailType {
  email: string
}

function Footer() {
  const navigate = useNavigate()

  const [registerToMailServices, { data: mailData, isLoading, error, isSuccess, isError }] =
    useUserMailSubscriptionMutation()
  const {seekerToken, providerToken} = useAppSelector((state)=>state.auth)
useEffect(()=>{
  toast.dismiss();
  if (isSuccess) {toast.success(mailData?.message);} 
  if(error){toast.error((error as any).data?.message)}
},[isError,isSuccess])
  const {
    control,
    handleSubmit, 
    register,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: footerSubscribemail,
  })

  const onSubmit = (values: EmailType,event:any) => {
    event.preventDefault()
    const payload = {
      email: values.email,
    };

    if (!payload.email) {
      toast.dismiss()
      toast.error("Please enter your email for subscribing");
      toast.clearWaitingQueue()
      return;
    }
    registerToMailServices(payload)
  };
 const debounceOnSubmit = useDebounce(onSubmit,320);
 const navigateAndScroll = useNavigateAndScroll();
  const scrollToSection = (id: string, pos?: number) => {
    navigateAndScroll('/', pos, id)
  };

  function checkIfHiddenBasedOnPath(path: string): boolean {
    const hiddenPaths = ['results', 'source-signup', 'terms-and-policies']; // footer is hidden on these paths
    return hiddenPaths.some(hiddenPath => path.includes(hiddenPath));
  }  

  return (
    <div className={`${(checkIfHiddenBasedOnPath(location.pathname) || seekerToken || providerToken) ? 'd-none' : 'd-block'}`}>
      <footer className="vedic-footer">
        <div className="footer-content py-5">
          <Container fluid>
            <Row>
              <Col md={3}>
                <div className="footer-link mx-3">
                  <ul className="footer">
                    <li className="list-item mb-3" onClickCapture={()=>{scrollToSection('hero', 0)}}>Home</li>
                    <li className="list-item mb-3" onClickCapture={()=>navigate('about-us')}>About us</li>
                    {isLaunched && <li className="list-item mb-3" onClickCapture={()=>{scrollToSection('discover-section')}}>How it works</li>}
                    <li className="list-item mb-3" onClickCapture={()=>{navigateAndScroll('help-center',0)}}>FAQs</li>
                    <li className="list-item mb-3" onClickCapture={()=>{scrollToSection('testimonials')}}>Testimonial</li>
                    <li
                      className="list-item mb-3"
                      onClick={() => navigateAndScroll("/source-signup", 0)}
                    >
                      Join as Source
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={3}>
                <div className="footer-contact mb-3">
                  <p className="color-primary">Contact us</p>
                  <a className="mail-heading cursor-pointer text-decoration-none" href="mailto:support@vedichom.com">
                    <span>
                      <img src={MailIcon} alt="MailIcon" />{" "}
                    </span>
                    <span className="">support@vedichom.com</span>
                  </a>
                  <p className="mail-heading cursor-pointer pt-2">
                    <span>
                      <img src={PhoneIcon} alt="PhoneIcon" />
                    </span>
                    +91 999 666 444
                  </p>
                  <div className="d-flex icon-tray gap-3 footer-social-icons">
                    <a href="https://www.facebook.com/profile.php?id=61558424099723&sk=photos" target="_blank">
                    <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/logos_facebook.png" alt="" height="20" width="20" />
                    </a>
                    {/* <a href="https://www.linkedin.com/company/100880647" target="_blank">
                      <FaLinkedin />
                    </a> 
                    For future implimententions*/} 
                    <a href="https://in.pinterest.com/vedichom/" target="_blank">
                    <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/pintrest.png" alt="" height="20" width="20" />
                    </a>
                    <a href="https://twitter.com/VedicHom" target="_blank">
                    <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/twitter.png" alt="" height="20" width="20" />
                    </a>
                    <a href="https://www.instagram.com/vedichom/" target="_blank">
                    <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/skill-icons_instagram.png" alt="" height="20" width="20" />
                    </a>
                    <a href="https://www.youtube.com/channel/UCjw8_-3Xk4mIOqtGacvxNjw" target="_blank">
                      <img src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/logos_youtube-icon.png" alt="" height="20" width="25" />
                    </a>
                  </div>
                </div>
              </Col>
              <Col md={3}>
              <p className="color-primary">Learn More</p>
                  <p className="mail-heading cursor-pointer" onClick={()=>navigateAndScroll('career', 0)}>Careers</p>
                  <p className="mail-heading cursor-pointer" onClick={()=>navigateAndScroll('site-map', 0)}>Site Map</p>
                  <p className="mail-heading cursor-pointer" onClick={()=>navigateAndScroll('press-center', 0)}>Press Center</p>
                  <p className="mail-heading cursor-pointer" onClick={()=>navigateAndScroll('refund-policy', 0)}>Refund Policy</p>
                  <p className="mail-heading cursor-pointer" onClick={()=>navigateAndScroll('privacy-policy', 0)}>Privacy Policy</p>
                  <p className="mail-heading cursor-pointer" onClick={()=>navigateAndScroll('terms-and-conditions', 0)}>Terms & Conditions</p>
              </Col>
              <Col md={3}>
                <form onSubmit={handleSubmit(debounceOnSubmit)} className="me-3">
                  <div className="d-flex flex-column justify-content-start gap-2 text-white subscribe-container">
                    <p className="text-left mb-2">Subscribe for more info</p>
                    <input
                      {...register("email")}
                      name="email"
                      type="email"
                      className="form-control email-input bg-black text-white"
                      placeholder="Enter your email"
                    />
                    <button
                      className="btn subscribe-btn rounded-3 mt-1 w-50"
                      type="submit"
                    >
                      Subscribe
                    </button>

                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="copyright-content py-4 text-center">
          <p className="text-white">
            Copyright © 2022 Vedic Website. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Footer
