import React, { Suspense, useEffect, useState } from "react"
import {
  Accordion,
  Modal,
  Container,
  Card,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
  Navbar,
  Nav,
  Dropdown,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import ProviderStepper from "../mastersStepper/ProviderStepper"
import LandingHeader from "../../../navbars/TopNavbar"
import Select from "react-select"
import { useAppDispatch } from "../../../../app/store"
import { useGetVedicLanguageQuery } from "../../../../features/Auth/authApi"
import { useAppSelector } from "../../../../app/hooks"
import {
  setIsStepValid,
  updateFunnel,
} from "../../../../features/ServiceFunnel/FeatProviderFunnel/providerFunnelSlice"
import FormMultiSelect from "../../../form/FormMultiSelect/FormMultiSelect"
import { Controller, useForm } from "react-hook-form"
import thumbs from "../../../../assets/images/thumbs-up 1.png"
import { IoCheckmarkDone, IoCloseOutline } from "react-icons/io5"
import { AiOutlineInfo } from "react-icons/ai"
import { toast } from "react-toastify"
import { useGetLocationSuggestionMutation, useProviderPhoneVerificationMutation, useUpdateAccountMutation } from "../../../../features/featProviderDashboard/FeatProviderApi"
import { reactHooksModule } from "@reduxjs/toolkit/dist/query/react"
import SpinnerMain from "../../../spinner/SpinnerMain"
import { SearchLocationInputBar } from "../../../modals/editProfile/locationUpdate/EditLocModal"
import { CiLocationOn } from "react-icons/ci"
import CustomTooltip from "../../../buttons/CustomTooltip"
import ToggleButton from "../../../buttons/ToggleButton"
import { PhoneInput } from 'react-international-phone';
import { MdOutlineEdit, MdOutlineLocationCity } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { isLaunched, MODALNAME } from "../../../../constants"
import { contactInfoPhoneSchema } from "../../../../utils/yupSchema/Schema"
import { sanitizePhoneNumber } from "../../../../utils/commonFunctions/CommonFunctions"
import { setModalName } from "../../../../features/Auth/ModalHandler/modalSlice"
import FormMultiSelectRef from "../components/Inputfield"

interface ProviderInfoForm {
  phone: string
  location: string
  city?: string
}
const ProviderInfo = () => {
  // const [selectedOption, setSelectedOption] = useState(null)
  // const [editMode, setEditMode] = useState<boolean>(false);
  // const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null)
  const [infoactive, setInfoActive] = useState(false)
  const handleInfoActive = () => {
    setInfoActive(!infoactive)
  }
  const [isDisabled, setDisabled] = useState<boolean>(true)
  const [
    phoneVerify,
    {
      data: phoneVerificationData,
      isLoading: phoneVerificationLoading,
      isSuccess: phoneVerificationSuccess,
      isError: providerPhoneError,
    },
  ] = useProviderPhoneVerificationMutation()
  const auth = useAppSelector((state: any) => state.auth)
  const dispatch = useAppDispatch()
  const { data } = useGetVedicLanguageQuery()
  const providerFunnelData = useAppSelector((state) => state.providerFunnel.providerFunnelData)
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control,
  } = useForm<any>({ resolver: contactInfoPhoneSchema })
  const [accountForm, { isLoading }] = useUpdateAccountMutation()
  const [formData, setFormData] = useState({location: '', city: ''})
  const providerFunnelDatas = useAppSelector(
    (state) => state.providerFunnel.providerFunnelData,
  )
  type StateType = { value: string; label: string }[]
  const [selectedValue, setSelectedValue] = useState<any>([])
  const handleSelectChange = (
    selectedValues: Array<{ value: string; label: string }>,
    setSelectedValue: React.Dispatch<React.SetStateAction<StateType>>,
    updateField: string,
  ) => {
    const payload = selectedValues.map((option) => option.value)
    setSelectedValue(selectedValues)
    dispatch(updateFunnel({ ...providerFunnelData, [updateField]: payload }))
  }
  const onSubmit = async (values: any) => {
    const payload = {
            mobileNo: values.phone ? sanitizePhoneNumber(values.phone) : '',
            countryCode: values.phone ? values.countryCode : null,
          }
    accountForm(payload).unwrap()
    .then((res)=>{
      toast.success(res.message)
      phoneVerify().unwrap().then(()=>{
        dispatch(setModalName(MODALNAME.OTP_VERIFICATION_AFTER_REGISTERATION_PHONE))
      })
    })
    .catch((err)=>{
      toast.error(err.data.message)
    })
  }
  useEffect(() => {
    const languageOption = Array.isArray(providerFunnelDatas?.language) &&
      providerFunnelDatas?.language?.map((item: any) => ({
        label: item,
        value: item,
      }))

    setSelectedValue(languageOption)

  }, [providerFunnelDatas])

  const setLocData = (place: string, city?: string) => {
    setFormData((prev: any) => {
      return {...prev, location: place, city: city}
    })
  }


  function handleToggleNumberShare(value: boolean): void {
    dispatch(updateFunnel({ revealPhoneNo: value }))
  }

  useEffect(() => {
    dispatch(setIsStepValid(formData.location === "" || selectedValue.length === 0 || formData.city === ""))
  }, [selectedValue, formData, providerFunnelDatas])

  useEffect(()=>{setFormData({location: providerFunnelDatas.location, city: providerFunnelDatas.city})},[])
  function handlePhoneNumberChange (e: any) {
    e.preventDefault()
    setDisabled((prev)=>(!prev))
  }
  const [mouseInorOut, setMouseIn] = useState<boolean>(false)
  function handlePhonenumberUpdate(phone: string, country: any) {
    const countryCode = "+" + country.country.dialCode
    const phoneNum = phone.replace(countryCode, "")
    setValue("phone", phoneNum)
    setValue("countryCode", countryCode)
  }
  useEffect(()=>{
  if(auth?.user?.mobileNo){  
    setValue("mobileNo", auth?.user?.countryCode + auth?.user?.mobileNo)
    setValue("phone", auth?.user?.mobileNo)
    setValue("countryCode", auth?.user?.countryCode)
    setDisabled(true)
  }
  },[auth])
  return (
    <>
      <section className="py-lg-1 funnel-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="">
                <div className="pb-0">
                  <p className="get-started-text font-14 fw-normal">
                    Your Phone Number
                    <span className=" text-danger">*</span>
                  </p>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                  <OverlayTrigger
                    placement="top"
                    overlay={isDisabled ? <Tooltip id="button-tooltip-2">Click on edit to change it and save changes</Tooltip> : <></>}
                  >
                  <div className="mb-3 provider-field provider-field--disabled disable-number-input shadow-none py-2 font-14 rounded-4 position-relative" 
                  onMouseEnter={()=>{setMouseIn(true)}}
                  onMouseLeave={()=>{setMouseIn(false)}}
                  > 
                      <Suspense>
                        <Controller
                          name="mobileNo"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput
                              defaultCountry={'in'}
                              {...field}
                              className="flag-sm-input"
                              placeholder={"Please change from my Account"}
                              disabled={isDisabled}
                              onChange={(phone, country) =>
                                handlePhonenumberUpdate(phone, country)
                              }
                            />
                          )}
                        />
                      </Suspense>
                      {isDisabled ? <Button type="button" className=" position-absolute end-0 top-0 bg-transparent h-100 border-0 " variant="outline" onClick={handlePhoneNumberChange}>
                        <MdOutlineEdit size={16} color={mouseInorOut?'red':"black"} /></Button>
                        : <Button className=" position-absolute end-0 top-0 bg-transparent h-100 border-0 " variant="outline"  type="submit">
                          <IoCheckmarkDone />
                          </Button>}
                  </div>
                    </OverlayTrigger>
                    </Form>
                  <Container>
                    <Row className="w-100">
                      <Col xs={10}>
                        <p className="font-14">{'Do you want to reveal your phone number to seekers '}
                          {/* <CustomTooltip content={"By accepting this your contact information will be shared with the student upon accepting a request"} /> */}
                        </p>
                      </Col>
                      <Col xs={2} className="pe-0">
                        <ToggleButton
                          disabled={auth?.user?.mobileNo==undefined || auth?.user?.mobileNo==''}
                         defaultVal={providerFunnelDatas.revealPhoneNo} isLoading={false} onChange={handleToggleNumberShare} />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className="pb-4">
                  <p className="get-started-text fw-normal font-14 mb-2">
                    Language <span className=" text-danger">*</span>
                  </p>
                  <FormMultiSelectRef
                    title=""
                    control={control}
                    options={data?.data || []}
                    onChange={(option) => {
                      toast.dismiss()
                      if (option?.length <= 9) handleSelectChange(option, setSelectedValue, "language")
                      else toast.error('You can only add upto 9 languages')
                    }}
                    name="select"
                    className="multi-select-bx"
                    value={selectedValue as any}
                    instructionName={'language'}
                  />
                </div>
                <div className="row flex-column  flex-md-row ">
                <div className="col-12 col-md-8">
                  <p className="get-started-text fw-normal font-14 mb-2 pt-0 pt-md-4">
                    <CiLocationOn />{" "}Location{" "}
                    <span className=" text-danger">*</span>
                  </p>
                  <SearchLocationInputBar 
                  isdisabled={false} 
                  defaultValue={providerFunnelDatas.location} 
                  setAtParent={function (place: string, city?: string): void {
                    return
                  }} 
                  setLocation = {function (place: string): void {
                    dispatch(updateFunnel({ location: place }))
                    setFormData((prev)=>({...prev, location: place}))
                  } }
                  setCity = {function (city: string): void {
                    dispatch(updateFunnel({ city: city }))
                    setFormData((prev)=>({...prev, city: city}))
                  } }
                  clearCity = {function (): void {
                    dispatch(updateFunnel({ city: '' }))
                    setFormData((prev)=>({...prev, city: ''}))
                  }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <p className="get-started-text fw-normal font-14 mb-2 pt-2 pt-md-4">
                    <MdOutlineLocationCity />{" "}City{" "}
                    <span className=" text-danger">*</span>
                  </p>
                  <Form.Control
                  value={providerFunnelData.city}
                  className="custom-input iframe-input form-control w-100 shadow-none"
                  disabled />
                </div>
                </div>
              </div>
            </Col>
            <Col lg={1}></Col>
            <Col lg={5}>
              <button className="popupinfo-btn" onClick={handleInfoActive}>
                <AiOutlineInfo />
              </button>
              <div
                className={
                  infoactive
                    ? "information-wrapper active"
                    : "information-wrapper"
                }
              >
                <div className="text-end mb-4">
                  <button
                    className="close-popup d-lg-none"
                    onClick={handleInfoActive}
                  >
                    <IoCloseOutline />
                  </button>
                </div>
                <div className="make-it-better">
                  <div
                    className="d-flex gap-2 mb-5 "
                    style={{ height: "2rem" }}
                  >
                    <img
                      src={thumbs}
                      alt="thumbs-up"
                      style={{ height: "2.5rem" }}
                    />
                    <h3 className="make-heading text-black fw-bolder fs-3 mt-2">
                    Did You Know? 
                    </h3>
                  </div>
                  <p className="font-lg-16 mb-0">
                  <strong>Elevate Your Profile 👑 — </strong> Selecting all relevant languages will ensure users know they can communicate in the language they're most comfortable with.
                  </p>
                  <ul>
                    <li>
                   <strong> Verifying your number</strong> with us will earn you a verified badge, boosting your credibility and trust on our platform.
                  </li>
                  <li>
                   <strong>Entering your location</strong>  allows users to quickly find you based on their preferred radius and location settings.
                  </li>
                  </ul>
      
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default ProviderInfo
