import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import "../../../form/FormInput.css"
import FormLabel from "../../../form/FormLabel"
import { useEffect, useState } from "react"

interface FormSelectProps {
  name: string
  title: string
  errors?: any
  control?: any
  options: any[]
  isMulti?: boolean
  placeholder?: string
  className?: string
  disabled?: boolean
  onChange?: (selectedOptions: any) => void
  value: string[],
  instructionName?: string
}

function FormMultiSelectRef({
  errors,
  title,
  value,
  name,
  control,
  options,
  isMulti,
  placeholder = "",
  className = "",
  onChange,
  disabled,
  instructionName,
}: FormSelectProps) {
  // const [ifNotAvailable, setNotAvailable] = useState<boolean>(false)
  // const [optionsIfNotAvailable, _optionsIfNotAvailable] = useState<any[]>([{value:'Other',label:'Other'}])
  // const handleInputChange = (e: string) => {
  //   const optionExists = options.some(option => 
  //     option.label.toLowerCase().includes(e.toLowerCase()) // Partial match check
  //   );
  //  if(!optionExists){
  //    setNotAvailable(true)
  //    _optionsIfNotAvailable([{value: e,label: e}])
  //   }
  //  else setNotAvailable(false)
  // }
  const [error, setError] = useState<string | null>(null);
  const instructionOption = {
    label: `Please add your ${instructionName || 'option'} if it's not listed`,
    value: "instructions",
    isDisabled: true,
  };
  const handleKeyDown = (e: { key: string; preventDefault: () => void }) => {
    // allow Enter
    const regex = /^[a-zA-Z\s]$/; // Allow only alphabets and whitespace
    const allowedKeys = ["Enter", "Backspace", "Delete", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

    if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault(); // Prevent default behavior if input is not allowed
      setError("Only alphabets and spaces are allowed.");
    } else {
      setError(null); // Clear error if the input is valid
    }
  };
  return (
    <>
      <Form.Group className={className}>
        <FormLabel title={title} />
        {error&&<p className=" text-danger fs-6">{error}</p>}
        <Controller
          name={name}
          rules={{ required: "This is a required Field" }}
          control={control}
          render={({ field }) => (
            <div className={!className ? "multi-select-custom" : ""}>
              <CreatableSelect
                // options={ifNotAvailable ? optionsIfNotAvailable : options}
                options={[instructionOption,...options]}
                value={value}
                placeholder="Select one or mutiple"
                isMulti
                menuPlacement="bottom"
                // onMenuClose={()=>setNotAvailable(false)}
                onChange={(selectedOptions) => {
                  field.onChange(selectedOptions)
                  if (onChange) {
                    onChange(selectedOptions)
                  }
                }}
                onKeyDown={handleKeyDown}
                // onInputChange={handleInputChange}
                styles={{
                  menu: (provided) => ({ ...provided, bottom: "auto", display: options?.length>0 ?'auto':'none' }),
                }}
                isDisabled={disabled}
              />
            </div>
          )}
        />
        {errors && errors[name] ? (
          <div className="invalid-feedback d-block">
            {errors[name]["message"]}
          </div>
        ) : null}
      </Form.Group>
    </>
  )
}

export default FormMultiSelectRef
