import React from 'react'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap'
import { BiSolidCopy, BiLogoLinkedin, BiLogoFacebook } from 'react-icons/bi'
import { FaXTwitter } from 'react-icons/fa6'
import { MdOutlineWhatsapp } from 'react-icons/md'
import { PiInstagramLogoDuotone } from 'react-icons/pi'
import { RxCross1 } from 'react-icons/rx'
import { toast } from 'react-toastify'
import { useDebounce } from '../../../utils/debouncer'
import { RiTelegramFill } from 'react-icons/ri'
import { detectDeviceType } from '../../../utils/commonFunctions/CommonFunctions'
import { DeviceType } from '../../../constants'

const PlatformShareModal = ({ show, handleClose, data }: any) => {
    const currentURL = 'https://www.vedichom.com '
    const text = 'Grow Your Teaching Reach – Join VedicHom and Connect with Knowledge Seekers Worldwide ' //TODO add content
    const thanksMessage = '.Thank you ! '
    const CopyText = () => {
        toast.dismiss();
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(`${text} ${currentURL} ${thanksMessage}`)
                .then(() => {
                    toast.success("Link copied to Clipboard");
                })
                .catch((error) => {
                    toast.error("Error copying URL to clipboard");
                });
        }
        else {
            toast.error("Clipboard is not supported on your browser");
        }
    };

    const handleCopyURL = useDebounce(CopyText, 500);

    const handleShare = (url: string) => {
        setTimeout(() => {
            window.open(url, '_blank');
        }, 300);
        CopyText()
    };
    return (
        <>
            <Modal
                className='custom-modal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
                animation={false}>
                <Modal.Body>
                    <Container>
                        <Row className=' justify-content-end'>
                            <Button className='noOutlineBtn d-lg-none position-relative' onClick={handleClose}><RxCross1 /></Button>
                        </Row>
                        <Row>
                            <Col lg={6} className='share-prelauch-left d-flex align-items-center justify-content-center'>
                                <div className='share-prelauch-left-inner '  >
                                    <img
                                        src="https://vedichom-dev.s3.ap-south-1.amazonaws.com/other/girl-illustrior-yoga.png"
                                        alt="Meditation Illustration"
                                        height="300"
                                        width="220"
                                    />
                                </div>
                            </Col>

                            <Col lg={6}>
                                <div className='share-head-text d-flex align-items-center justify-content-center mt-3'>
                                    Share with your friends and family
                                </div>
                                <div>
                                    <ul className="share-cards-social-links-box mt-4">
                                        <li className='user-select-none'>
                                            <Button
                                                className="share-cards-social-link"
                                                onClick={handleCopyURL}
                                            >
                                                <span>
                                                    <BiSolidCopy size={18} />
                                                </span>
                                                Copy Link
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className="share-cards-social-link"
                                                onClick={() => handleShare(detectDeviceType()===DeviceType.Mobile?`https://wa.me/send?text=${encodeURIComponent(`${text} ${currentURL} ${thanksMessage}`)}&source=${currentURL}`:`https://web.whatsapp.com/send?text=${encodeURIComponent(`${text} ${currentURL} ${thanksMessage}`)}&source=${currentURL}`)}
                                            >
                                                <span>
                                                    <MdOutlineWhatsapp size={18} />
                                                </span>
                                                Whatsapp
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className="share-cards-social-link"
                                                onClick={() => handleShare(`https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(currentURL)}&hashtags=vedichom`)}
                                            >
                                                <span>
                                                    <FaXTwitter size={18} />
                                                </span>
                                                Twitter
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className="share-cards-social-link"
                                                onClick={() => handleShare(`https://t.me/share/url?url=${encodeURIComponent(currentURL)}&text=${encodeURIComponent(text)}`)}
                                            >
                                                <span>
                                                    <RiTelegramFill size={18} />
                                                </span>
                                                Telegram
                                            </Button>
                                            {/* <Button
                                                className="share-cards-social-link"
                                                onClick={() => handleShare(`https://www.instagram.com/?url=${currentURL}&text=${text}`)}
                                            >
                                                <span>
                                                    <PiInstagramLogoDuotone size={18} />
                                                </span>
                                                Instagram
                                            </Button> */}
                                        </li>
                                        <li>
                                            <Button
                                                className="share-cards-social-link"
                                                onClick={() => handleShare(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentURL)}&title=${encodeURIComponent(currentURL)}&summary=${encodeURIComponent(currentURL)}&source=${encodeURIComponent('vedichome.online')}`)}
                                            >
                                                <span>
                                                    <BiLogoLinkedin size={18} />
                                                </span>
                                                Linkedin
                                            </Button>
                                        </li>
                                        <li>
                                            <Button
                                                className="share-cards-social-link"
                                                onClick={() => handleShare(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentURL)}&quote=${encodeURIComponent(text)}`)}
                                            >
                                                <span>
                                                    <BiLogoFacebook size={18} />
                                                </span>
                                                Facebook
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal></>
    )
}

export default PlatformShareModal